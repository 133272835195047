.section-team {
  @include phone {
    margin: $gap 0;
  }
  @include tablet {
    margin-top: $gap * 2;
    margin-bottom: $gap * 2;
  }
}
.team-title {
  width: 100%;
  display: block;
  @include tablet {
    align-items: flex-start;
  }
  h2 {
    color: $mainGreen;
    width: 100%;
    font-family: $mainFontBold;
    @include phone {
      font-size: 4rem;
      margin-left: 3rem;
    }
    @include tablet {
      font-size: 8rem;
      margin-left: 0rem;
    }
    @include desktop {
      font-size: 10rem;
      margin-left: 3rem;
    }
  }
}

.section-team {
  .members {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: $gap;
    margin-bottom: $gap;
    gap: 1em;
    height: 7em;

    @include phone {
      font-size: 0.7em;
    }
    @include tablet {
      font-size: 1em;
    }
    @include desktop {
      font-size: 1em;
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;

      color: var(--foreground-color);
      cursor: pointer;
      img {
        border-radius: 50%;
        aspect-ratio: 1 /1;
        @include phone {
          width: 3em;
        }
        @include tablet {
          width: 6em;
        }

        &.selected {
          outline: 2px solid $mainGreen;
          outline-offset: 2px;
          @include phone {
            width: 3.5em;
          }
          @include tablet {
            width: 6.5em;
          }
          @include desktop {
            width: 6.5em;
          }
        }
      }
    }
  }
}

.team-full {
  position: sticky;
  margin: $gap;
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1.5 / 1;
  position: relative;
  @include phone {
    flex-direction: column;
    background-size: contain;
  }
  @include tablet {
    flex-direction: row;
    background-size: cover;
  }

  img {
    max-height: 50vh;
  }
}

.team-card {
  display: flex;
  align-items: center;
  margin: $gap;
  gap: 2em;
  flex-direction: column;
  @include tablet {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
  }
  > * {
    flex: 50%;
  }
  .member-picture {
    text-align: center;
    color: var(--foreground-color);
    img {
      max-height: 50vh;
      max-width: 40vw;
    }
    .name {
      font-family: $mainFontBold;
    }
    @include tablet {
      // text-align: left;
      margin-left: 5rem;
      .name,
      .title {
        // margin-right: 45%;
        text-align: center;
      }
    }
  }
  .member-description {
    p {
      padding: 0.5em;
      line-height: 1.4em;
      letter-spacing: 0.2ch;
      @include phone {
        font-size: 0.8em;
      }
      @include tablet {
        font-size: 1em;
        margin-right: 5rem;
      }
    }
  }
}
