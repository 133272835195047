.hero-img {
  @include phone {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(../build/img/hero/main-mobile.webp);
  }
  @include tablet {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(../build/img/hero/banner.jpg);
  }
  background-position: center center;
  background-size: cover;
  height: 100vh;
  min-height: 60rem;
  width: 100%;
}
.hero-shot {
  position: absolute;
  clip: rect(0, auto, auto, 0);
  display: flex;
  justify-content: flex-end;
  height: inherit;
  height: 100vh;
  width: 100vw;
  @include phone {
    width: 100%;
    align-items: flex-end;
  }
  @include tablet {
    width: 100%;
    align-items: center;
  }

  @include desktop {
    width: 100%;
    align-items: center;
  }
}
.hero-content {
  display: flex;
  position: fixed;
  flex-direction: column;
  @include phone {
    right: 5%;
    bottom: 6%;
    width: 73%;
  }
  @include tablet {
    right: 15%;
    width: 50rem;
    bottom: 10%;
  }
  p {
    text-shadow: 1px 1px 10px $black;
    font-weight: bold;
    color: white;
    text-align: left;
    margin: 4rem 0rem;
    @include phone {
      font-size: $pSize;
      line-height: 2.6rem;
    }
    @include tablet {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }
  h1 {
    color: white;
    text-align: left;
    font-family: $mainFontBold;
    @include phone {
      font-size: 6rem;
    }
    @include tablet {
      font-size: 12rem;
    }
  }
  h3 {
    color: white;
    text-shadow: 1px 1px 10px $black;
    text-transform: uppercase;
    letter-spacing: 0.45em;
    font-weight: $light;
    text-align: left;
    @include phone {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
    @include tablet {
      font-size: 3.2rem;
      margin-bottom: $gap/2;
    }
  }
}
