.contactUs-img {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    filter: brightness(0.5) saturate(1.4);
    background-color: black;
    background-image: url(../build/img/contactus/desktop.webp);
    background-position: center center;
    background-size: cover;
  }
  @include phone {
    &::before {
      background-image: url(../build/img/contactus/desktop.webp);
      background-position: -220px center;
      background-size: cover;
    }
    height: 80vh;
  }
  @include tablet {
    height: 100vh;
    &::before {
      background-image: url(../build/img/contactus/desktop.webp);
      background-position: center center;
      background-size: cover;
    }
  }
  min-height: 80rem;
  height: 100vh;
  width: 100%;
}

.wrap-form-contact {
  display: flex;
  color: $white;
  @include phone {
    padding: 0 3rem;
    justify-content: flex-start;
  }
  @include tablet {
    justify-content: end;
    width: 80rem;
  }
  @include desktop {
    justify-content: end;
    width: 100%;
    max-width: 140rem;
  }
  .form-contact {
    margin-top: 7rem;
    @include phone {
      width: 28rem;
    }
    @include tablet {
      width: 46rem;
    }
    h1 {
      font-family: $mainFontBold;
      margin-bottom: 2rem;
      @include phone {
        font-size: 4rem;
      }
      @include tablet {
        font-size: 8.5rem;
      }
    }
    input,
    textarea {
      color: $white;
      width: 100%;
      background: none;
      border: 0;
      padding: 1rem 0;
      @include phone {
        font-size: 1.45rem;
        border-bottom: $white 2px solid;
      }
      @include tablet {
        font-size: 1.8rem;
        border-bottom: $white 1px solid;
      }
    }
    small {
      display: block;
      height: 2.5rem;
      margin-top: 0.5rem;
    }
    input:focus,
    textarea:focus {
      outline: none;
      border: 0;
      border-bottom: $white 2px solid;
    }
    input::placeholder,
    textarea::placeholder {
      color: $white;
    }
    textarea {
      padding: 0;
      height: 10rem;
    }
    .required {
      border-bottom: red 2px solid;
    }
    button {
      font-weight: bold;
      color: $white;
      background: none;
      border-radius: 10px;
      border: 0.2rem solid $white;
      margin-top: 2rem;
      cursor: pointer;
      @include phone {
        padding: 1rem 6rem;
        font-size: 1.4rem;
      }
      @include tablet {
        padding: 2rem 8rem;
        font-size: 2.6rem;
      }
    }
    button:hover {
      border: 0.2rem solid #c9c9c9;
      color: #c9c9c9;
    }
  }
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid $mainGreen;
  -webkit-animation: spin 3s linear infinite; /* Safari */
  animation: spin 3s linear infinite;
  @include phone {
    width: 16px;
    height: 16px;
  }
  @include tablet {
    width: 30px;
    height: 30px;
  }
}

.swal2-default-outline {
  outline: 0 !important;
  border: none !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
