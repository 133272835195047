html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--background-color);
  transition: background-color 0.3s ease-in-out;
  font-family: $mainFont;
  font-size: 1.6rem;
  width: 100vw;
  overflow-x: hidden;

  &.fijar-body {
    overflow: hidden;
  }
}

p {
  font-size: 2rem;
  color: var(--foreground-color);
  margin: 0;
  padding: 0;
}
.container {
  width: 95%;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
h1,
h2,
h3 {
  margin: 0;
}
h1 {
  font-size: 4rem;
  font-family: $mainFontBold;
}
h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 3rem;
}
