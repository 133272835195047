.footer {
  background-color: var(--background-color);
  border-top-style: solid;
  border-top-color: $mainGreen;
  padding: $gap 0;

  @include tablet {
    flex-direction: row;
  }

  .container {
    width: 100%;
    max-width: 140rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @include tablet {
      flex-direction: row;
    }

    .contact-info-content {
      order: 2;
      margin-bottom: 2rem;
      @include tablet {
        order: 1;
      }
    }

    .main-logo-content {
      order: 1;
      margin-bottom: 2rem;
      @include tablet {
        order: 2;
      }
    }

    .social-media-content {
      order: 3;
    }
  }

  .wrap-info-footer {
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
    @include tablet {
      width: 33.3%;
    }
  }

  .main-logo-content {
    text-align: center;
    h3 {
      color: var(--foreground-color);
      font-family: $mainFontBold;
      font-size: 4rem;
    }
    p {
      font-size: 1.4rem;
    }
    img {
      width: 6rem;
      margin-bottom: 1rem;
    }
  }

  .social-media-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    //max-width: 40rem;
    @include tablet {
      align-items: flex-end;
    }

    .social-network {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      width: 100%;
    }

    p {
      font-family: $mainFontLight;
      text-align: right;
      line-height: 2.5rem;
    }
  }

  .green-text {
    margin-top: 1rem;
    color: $mainGreen;
  }



  .contact-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include tablet {
      align-items: flex-start;
    }
    .wrap-paragrahp {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      @include tablet {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .content-info {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      

      .wrap-icon-info {
        display: block;
        width: 37.5px;
        text-align: center;
        margin-right: 1.5rem;
        
      }
      
      img {
        display: block;
        margin: 0 auto;
      }
      p {
        width: 80%;
        font-size: $pSize;
        
      }
    }
    .especial-info {
     
  }
  }
  .space-between {
    margin-left: 2rem;
  }

  .p-copyright {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .green-title {
    margin-right: 1rem;
    color: $mainGreen;
  }
}
