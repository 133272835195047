@mixin phone {
  @media (min-width: $phone) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin grid($columnas, $gap) {
  display: grid;
  grid-template-columns: repeat($columnas, 1fr);
  gap: $gap;
}

@mixin outline($size, $blur, $color) {
  text-shadow: 
    $size * -1 $size * -1 $blur $color,
    $size $size * -1 $blur $color,
    $size * -1 $size $blur $color, 
    $size $size $blur $color;
}
