.header {
  .content-header {
    padding: $gap / 2 0;
    @include phone {
      display: none;
    }
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }
    .menu-close {
      display: none;
    }
    .logo-desktop {
      height: 36px;
      color: red;
      width: auto;
    }
  }
  .hamburguer-menu {
    height: 100vh !important;
    position: relative;
    @include phone {
      display: block;
    }
    .menu-close {
      display: block;
      position: absolute;
      top: 35px;
      right: 30px;
    }
    .logo-desktop {
      height: 45px;
      width: auto;
      position: absolute;
      top: 22px;
      left: 30px;
      @include phone {
        display: block;
      }
    }
    .main-nav {
      display: block;
      gap: 2rem;
      text-align: center;
      font-weight: $bold;
      margin-top: 70px;
      a {
        display: block;
        color: $mainGreen;
        font-size: 3rem;
        margin-bottom: $gap;
        &:hover {
          font-weight: $bold;
        }
      }
    }
  }
}

.main-nav {
  display: flex;
  z-index: 99;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  a {
    color: $white;
    font-size: 2rem;
    text-shadow: 1px 1px 10px $black;
    &:hover {
      text-shadow: 1px 1px 20px $black;
      font-weight: $bold;
    }
  }
}

.main-menu {
  position: absolute;
  width: 100%;
}

.menu-tab {
  display: block;
  position: fixed;
  top: 10px;
  right: 15px;
  cursor: pointer;
  z-index: 2;
}

.logo-white {
  position: absolute;
  top: 10px;
  left: 15px;
}

.fixed-menu {
  background-color: var(--background-color);
  transition: background-color 0.3s ease-in-out;
  position: fixed;
  z-index: 99;
  .content-header {
    height: 62px;
  }
  .main-nav {
    a {
      color: $mainGreen;
      font-size: 2rem;
      text-shadow: revert;
      &:hover {
        font-weight: $bold;
      }
    }
  }
}
