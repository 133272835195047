.wrap-projects {
  .project-slider {
    width: 100%;
    min-height: 80rem;
    height: 100vh;
  }
  .firts-slider {
    width: 50%;
    margin-top: 5%;
    h1 {
      font-weight: $mainFontBold;
      color: $mainGreen;
      font-weight: bolder;
      margin: 0 0 4rem 3rem;
      @include tablet {
        font-size: 8rem;
        width: 30rem;
      }
      @include desktop {
        font-size: 10rem;
        width: 38rem;
      }
    }
    p {
      margin: 0 15% 6rem 3.5rem;

      @include tablet {
        font-size: 2rem;
      }
      @include desktop {
        font-size: 2.5rem;
      }
    }
    img {
      transform: translateY(50%);
      display: block;
      width: 52px !important;
      position: absolute;

      z-index: 99;
      right: 5rem;
      cursor: pointer;
      @include tablet {
        top: 3rem;
      }
      @include desktop {
        top: 50%;
      }
    }
  }
  .last-slider {
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (prefers-color-scheme: light) {
      background-image: url(../build/img/fondo-blanco-proyectos.svg);

      :root.invert & {
        background-image: url(../build/img/fondo-negro-proyectos.png);
      }
    }
    @media (prefers-color-scheme: dark) {
      background-image: url(../build/img/fondo-negro-proyectos.png);

      :root.invert & {
        background-image: url(../build/img/fondo-blanco-proyectos.svg);
      }
    }
    img {
      display: block;
      margin-top: 30rem;
      width: 52px !important;
      cursor: pointer;
    }
  }
  .slider-show {
    position: relative;
    overflow: hidden;
    background-position: center;
    transition: 0.3s;
    cursor: pointer;
    @include phone {
      background-size: auto 100vh;
    }
    @include tablet {
      background-size: 100% auto;
    }
    .project-content {
      color: $white;
      position: absolute;
      @include phone {
        bottom: 16rem;
        left: 3rem;
      }
      @include tablet {
        bottom: 14.3rem;
        left: 14.7rem;
      }
      h2 {
        font-family: $mainFontBold;
        @include phone {
          font-size: 4rem;
          width: 30rem;
        }
        @include tablet {
          font-size: 10rem;
          width: 100%;
        }
      }
      p {
        color: $white;
        @include phone {
          font-size: 3rem;
        }
        @include tablet {
          font-size: 4rem;
        }
      }
    }
  }
  .slider-show:hover {
    background-position: center;
    @include phone {
      background-size: auto 100vh;
    }
    @include tablet {
      background-size: 115% auto;
    }
  }
  .slider-desk {
    display: flex;

    .slide {
      min-width: 100vw;
      &.firts-slider,
      &.last-slider {
        min-width: 70vw;
        width: 70vw;
      }
    }
  }
  .firts-slider-content {
    position: absolute;
    top: 3rem;
    left: 3rem;
    color: $white;
    p {
      position: relative;
      color: $white;
      font-size: $pSize;
    }
    img {
      position: absolute;
      width: 3rem;
      right: 0rem;
      top: 3rem;
    }
  }
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
