@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}


@include font('HurmeGeometricSans', '../build/font/HurmeGeometricSans1');
@include font('HurmeGeometricSans-bold', '../build/font/HurmeGeometricSans1_Bold');
@include font('HurmeGeometricSans-black', '../build/font/HurmeGeometricSans1_Black');
@include font('HurmeGeometricSans-light', '../build/font/HurmeGeometricSans1_Light');