.section-sustainability {
  display: flex;
  flex-direction: column;
  @include phone {
    margin: $gap 0;
  }
  @include tablet {
    margin-top: $gap * 2;
    margin-bottom: $gap * 2;
  }
  .sustainability-grid {
    height: 100%;
    flex-grow: 1;

    &.gap {
      gap: $gap * 2;
    }
    @include tablet {
      @include grid(2, 1);
    }
  }
  .sustainability-title {
    transition: all 2s ease-out;
    width: 100%;
    display: block;
    @include phone {
      margin-bottom: $gap;
    }
    @include tablet {
      height: inherit;
      margin-bottom: $gap * 2;
    }
    h2 {
      color: $mainGreen;
      font-family: $mainFontBold;
      margin-left: 3rem;
      @include phone {
        font-size: 4rem;
      }
      @include tablet {
        font-size: 8rem;
      }
      @include desktop {
        font-size: 10rem;
      }
    }
  }

  .sustainability-p {
    visibility: hidden;
  }

  .img-sustainability {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;

    width: 100%;
    min-height: 40rem;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    @include phone {
      min-height: 40rem;
    }
    @include tablet {
      min-height: 60rem;
      height: 50vh;
    }
    .imageTitle {
      color: $white;
      width: 80%;
      margin-top: 1.5rem;
      font-size: 3rem;
      font-family: $mainFontBold;

      @include tablet {
        font-size: 7rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .background-sustainability {
    background-image: url(../build/img/sustainability/strategy.webp);
    background-color: #a1bde1;
    background-size: auto 100%;
    @include phone {
      background-size: 100% auto;
      background-position: bottom;
    }
  }
  .background-reports {
    background-image: url(../build/img/sustainability/reports.webp);
  }

  .sustainability-text {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    .text-area {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        width: 80%;
        line-height: 2.6rem;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: var(--foreground-color);
        background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='white'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
        background-color: $mainGreen;
        background-size: 1.5em 1em;
        background-repeat: no-repeat;
        background-position: center right;
        border: 3px solid $mainGreen;
        border-radius: 11px;
        padding: 1rem calc(2rem + 1.5em) 1rem 2rem;
        position: relative;
      }

      @include tablet {
        margin-left: 0;
        margin-right: $gap;
        p {
          width: 100%;
          line-height: 3.6rem;
        }
      }
    }
  }

  .gap-top {
    margin-top: $gap;
  }

  .gap-bottom {
    margin-bottom: $gap;
  }
}
