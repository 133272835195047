@import 'node_modules/react-modal-video/scss/modal-video.scss';

.section-about-us {
  @include phone {
    margin: $gap 0;
  }
  @include tablet {
    @include grid(2, $gap * 2);
    margin-top: $gap * 2;
    margin-bottom: $gap * 2;
  }
}
.image-aboutUs {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  // @include tablet {
  //   height: auto;
  // }
  width: 100%;
  height: 30rem;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @include tablet {
    height: inherit;
  }
  a {
    visibility: hidden;
  }
}

.background-building {
  //../../../build/img/grande/office.webp
  /* background-image: url(../build/img/grande/building.webp); */
}
.background-office {
  //../../../build/img/grande/office.webp
  background-image: url(../build/img/grande/office.webp);
}
.background-parking {
  //../../../build/img/grande/office.webp
  background-image: url(../build/img/grande/Mask-Group-102.2.webp);
}
.background-tennisCourt {
  //../../../build/img/grande/office.webp
  background-image: url(../build/img/grande/Mask-Group-103.3.webp);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.aboutsUs-title {
  width: 100%;
  display: block;
  @include tablet {
    align-items: flex-start;
  }
  h2 {
    color: $mainGreen;
    width: 80%;
    font-family: $mainFontBold;
    @include phone {
      font-size: 4rem;
      margin-left: 3rem;
    }
    @include tablet {
      font-size: 8rem;
      margin-left: 0rem;
    }
    @include desktop {
      font-size: 10rem;
    }
  }
}

.about-us-text {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .react-tabs {
    width: 80%;
  }

  @include tablet {
    margin-left: 0;
    margin-right: $gap;
    .react-tabs {
      width: 100%;
      line-height: 3.6rem;
    }
  }
}

.gap-top {
  margin-top: $gap;
}

.gap-bottom {
  margin-bottom: $gap;
}

.gap-hidden {
  visibility: hidden;
}

.react-tabs {
  &__tab-list {
    margin: $gap 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0;
  }

  &__tab {
    list-style: none;
    color: var(--foreground-color) !important;
    cursor: pointer;

    @include phone {
      padding: 3px 12px 0px 0px;
    }

    @include tablet {
      padding: 6px 24px 0px 0px;
    }

    &--selected {
      background: var(--background-color);
      border-color: #aaa;
      color: var(--foreground-color) !important;
      font-weight: normal;
      font-family: $mainFontBold;
      font-size: 1.1em;
      transition: all 0.5s ease-out;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
      transition: all 0.5s ease-out;
    }
  }

  &__tab-panel {
    display: none;

    .aboutUs {
      @include grid(2, $gap);
      grid-template-columns: 1fr 6fr;
      .img-about {
        width: 6rem;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        a {
          visibility: hidden;
        }
      }

      .background-hands {
        background-image: url(../build/img/grande/Group-74-Blanco.svg);
      }

      .background-atomicHandshake {
        background-image: url(../build/img/grande/Group-75-Blanco.svg);
      }

      .background-handshake {
        background-image: url(../build/img/grande/Group-76-Blanco.svg);
      }
    }

    &--selected {
      display: block;
      animation: fadeIn 1s;
      -webkit-animation: fadeIn 1s;
      -moz-animation: fadeIn 1s;
      -o-animation: fadeIn 1s;
      -ms-animation: fadeIn 1s;
    }

    p {
      &.bold {
        font-family: $mainFontBold;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        .text-content {
          display: flex;
          flex-direction: column;
        }
        p {
          text-align: left;
          text-justify: inter-word;
          justify-content: center;
        }
      }
    }
  }

  .p-dot-inside {
    list-style-type: disc;
    list-style-position: inside;
    display: list-item;
  }

  .img-background-companies {
    max-width: 25rem;
  }
}

.react-tabs__tab-list {
  margin: 3rem 0 10px 0;
  color: $darkGray;
}
.react-tabs__tab--selected {
  color: $darkGray;
}
.react-tabs__tab {
  @include tablet {
    padding: 6px 10px 0px 0px;
  }
  @include desktop {
    padding: 6px 24px 0px 0px;
  }
}
