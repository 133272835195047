$mainFont: 'HurmeGeometricSans', sans-serif;
$mainFontBold: 'HurmeGeometricSans-bold', sans-serif;
$mainFontBlack: 'HurmeGeometricSans-black', sans-serif;
$mainFontLight: 'HurmeGeometricSans-light', sans-serif;

// Colores
$mainGreen: #b1c91e;
$gray: #58585b;
$darkGray: #464749;
$white: #fff;
$black: #1d1d1d;

// Media Queries
$phone: 250px;
$tablet: 780px;
$desktop: 1200px;

// Fuentes
$light: 300;
$regular: 400;
$bold: 700;

//fuentes size
$titleSize: 9rem;
$subTitleSize: 2.2rem;
$pSize: 1.6rem;
// Separaciones
$gap: 5rem;

@media (prefers-color-scheme: light) {
  :root {
    --background-color: #fff; // $black
    --foreground-color: #1d1d1d;
    --icon-color: #58585b;
  }
  :root.invert {
    --background-color: #1d1d1d; // $black
    --foreground-color: #fff;
    --icon-color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1d1d1d; // $black
    --foreground-color: #fff;
    --icon-color: #fff;
  }
  :root.invert {
    --background-color: #fff; // $black
    --foreground-color: #1d1d1d;
    --icon-color: #58585b;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    .theme-invert {
      filter: initial;
    }
  }
  :root.invert {
    .theme-invert {
      filter: invert(1);
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    .theme-invert {
      filter: invert(1);
    }
  }
  :root.invert {
    .theme-invert {
      filter: initial;
    }
  }
}
